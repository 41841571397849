<template>
  <b-card>
    <h4 class="mb-2">
      {{ $t("Project Settings") }}
    </h4>

    <settings-view
      :settings="settings"
      :form="form"
      :is-loading="isLoading"
    />

    <b-button
      class="float-right mt-2"
      variant="primary"
      :disabled="isLoading"
      @click="saveProjectSettings"
    >
      {{ $t("Save") }}
    </b-button>
  </b-card>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import SettingsView from '@/views/common/components/SettingsView.vue'

export default {
  components: {
    BCard,
    BButton,
    SettingsView,
  },
  data() {
    return {
      isLoading: false,
      form: {
        enableCloudServices: false,
      },
      settings: {
        enableCloudServices: {
          icon: 'CloudIcon',
          name: 'enableCloudServices',
          title: this.$t('cloud.enable'),
          description: this.$t('cloud.share'),
          inputType: 'switch',
        },
      },
    }
  },
  computed: {
    company() { return this.$store.state.project.selectedCompany },
    project() { return this.$store.state.project.selectedProject },
  },
  mounted() {
    this.getProjectSettings()
  },
  methods: {
    getProjectSettings() {
      this.isLoading = true
      useApollo.company.getProjectSettings({ project: this.project }).then(response => {
        this.form.enableCloudServices = response.data.project.settings[0].information.enableCloudService
      }).finally(() => {
        this.isLoading = false
      })
    },
    saveProjectSettings() {
      this.isLoading = true
      const params = {
        companyUid: this.company,
        projectUid: this.project,
        input: {
          enableCloudServices: this.form.enableCloudServices,
        },
      }

      useApollo.company.updateProjectCloudSettings(params).then(response => {
        this.showSuccess(response.data.updateProjectCloudSettings.message)
        this.$store.dispatch('project/setProjectSettings', {
          enableCloudServices: this.form.enableCloudServices,
        })
      }).catch(error => {
        this.showError(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
